import {actionTypes} from "../constants";

const {CYCLES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    cycles: [],
};

const cyclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CYCLES_ACTION_TYPES.CYCLES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case CYCLES_ACTION_TYPES.CYCLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case CYCLES_ACTION_TYPES.CYCLES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: [...state.cycles, action.payload],
            };
        case CYCLES_ACTION_TYPES.CYCLES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case CYCLES_ACTION_TYPES.CYCLES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case CYCLES_ACTION_TYPES.CYCLES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: action.payload,
            };
        }
        default:
            return state;
    }
}

export default cyclesReducer;