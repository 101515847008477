import {actionTypes} from "../constants";

const {SCALES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    scales: [],
};

const scalesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCALES_ACTION_TYPES.SCALES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case SCALES_ACTION_TYPES.SCALES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case SCALES_ACTION_TYPES.SCALES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                scales: [...state.scales, action.payload],
            };
        case SCALES_ACTION_TYPES.SCALES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                scales: state.scales.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case SCALES_ACTION_TYPES.SCALES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                scales: state.scales.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case SCALES_ACTION_TYPES.SCALES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                scales: action.payload,
            };
        }
        default:
            return state;
    }
}

export default scalesReducer;