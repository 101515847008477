import {applyMiddleware, combineReducers, createStore} from "redux";
import {createWrapper} from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

import {
    member,
    industries,
    scales,
    stages,
    statuses,
    countries,
    roles,
    permissions,
    valueDrivers,
    companies,
    users,
    userCompaniesRoles,
    assessments,
    session,
    answers,
    prioItems,
    prioActions,
    strategicInitiatives,
    actionItems,
    cycles,
    externalUsers,
    sidebar,
    assessmentUsers,
    academies,
} from "../reducers";

const bindMiddleware = (middleware) => {
    return composeWithDevTools(applyMiddleware(...middleware));
}

const combinedReducer = combineReducers({
    member,
    industries,
    scales,
    stages,
    statuses,
    countries,
    roles,
    permissions,
    valueDrivers,
    companies,
    users,
    userCompaniesRoles,
    assessments,
    session,
    answers,
    prioItems,
    prioActions,
    strategicInitiatives,
    actionItems,
    cycles,
    externalUsers,
    sidebar,
    assessmentUsers,
    academies,
})

// const reducer = (state, action) => {
//     if (action.type === HYDRATE) {
//         return {
//             ...state, // use previous state
//             ...action.payload, // apply delta from hydration
//         };
//     } else {
//         return combinedReducer(state, action);
//     }
// }

const configureStore = (reducer) =>
    createStore(reducer, bindMiddleware([thunkMiddleware]));

export const makeStore = () => {
    const isServer = typeof window === 'undefined'
    if (isServer) {

        return configureStore(combinedReducer);

    } else {
        const {persistStore, persistReducer} = require('redux-persist');
        const storage = require('redux-persist/lib/storage/session').default;

        const persistConfig = {
            key: 'nextjs',
            //whitelist: ["member"],
            storage
        };

        const persistedReducer = persistReducer(persistConfig, combinedReducer);
        const store = configureStore(persistedReducer);

        // @ts-ignore
        store.__persistor = persistStore(store);

        return store;
    }
};

export const wrapper = createWrapper(makeStore);