import {actionTypes} from "../constants";

const {ASSESSMENT_USERS_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    assessmentUsers: [],
};

const assessmentUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentUsers: action.payload,
            };
        }
        case ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_CLEANUP: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentUsers: [],
            };
        }
        default:
            return state;
    }
}

export default assessmentUsersReducer;