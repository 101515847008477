const colors = {
    blue: "rgb(21,40,107)",
    red: "rgb(255,0,36)",
    orange: "rgb(218,200,0)",
    green: "rgb(0,219,0)",
    black: "rgb(0,0,0)",
    blueLight: "rgb(53,65,109)",
    redLight: "rgb(248,114,136)",
    orangeLight: "rgb(220,208,70)",
    greenLight: "rgba(155,248,155)",
    blueBright: "rgba(39,77,208,1)",
    redBright: "rgba(184,5,31,1)",
    orangeBright: "rgba(229,238,11,1)",
    greenBright: "rgba(7,245,7,1)",
    textColor: "rgba(0,36,74,1)",
}

export default colors;