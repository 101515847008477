export const authenticate = "/api/authenticate";
export const industries = "/api/industries";
export const scales = "/api/scales";
export const stages = "/api/stages";
export const countries = "/api/countries";
export const roles = "/api/roles";
export const permissions = "./api/permissions";
export const valueDrivers = "/api/value-drivers";
export const companies = "/api/companies";
export const users = "/api/users";
export const userCompaniesRoles = "/api/usercompaniesroles";
export const assessments = "/api/assessments";
export const answers = "/api/answers";
export const session = "/api/session";
export const prioItems = "/api/prioItems";
export const prioActions = "/api/prioActions";
export const strategicInitiatives = "/api/strategicInitiatives";
export const actionItems = "/api/actionitems";
export const cycles = "/api/cycles";
export const externalUsers = "/api/externalusers";
export const assessmentUsers = "/api/assessmentusers";
export const academies = "/api/academies";
