export const INDUSTRIES_ACTION_TYPES = {
    INDUSTRIES_LOADING: 'INDUSTRIES_LOADING',
    INDUSTRIES_ERROR: "INDUSTRIES_ERROR",
    INDUSTRIES_CLEANUP: "INDUSTRIES_CLEANUP",
    INDUSTRIES_INSERT: "INDUSTRIES_INSERT",
    INDUSTRIES_UPDATE: "INDUSTRIES_UPDATE",
    INDUSTRIES_DELETE: "INDUSTRIES_DELETE",
    INDUSTRIES_GET_ALL: "INDUSTRIES_GET_ALL",
};

export const SCALES_ACTION_TYPES = {
    SCALES_LOADING: 'SCALES_LOADING',
    SCALES_ERROR: "SCALES_ERROR",
    SCALES_CLEANUP: "SCALES_CLEANUP",
    SCALES_INSERT: "SCALES_INSERT",
    SCALES_UPDATE: "SCALES_UPDATE",
    SCALES_DELETE: "SCALES_DELETE",
    SCALES_GET_ALL: "SCALES_GET_ALL",
};

export const STAGES_ACTION_TYPES = {
    STAGES_LOADING: 'STAGES_LOADING',
    STAGES_ERROR: "STAGES_ERROR",
    STAGES_CLEANUP: "STAGES_CLEANUP",
    STAGES_INSERT: "STAGES_INSERT",
    STAGES_UPDATE: "STAGES_UPDATE",
    STAGES_DELETE: "STAGES_DELETE",
    STAGES_GET_ALL: "STAGES_GET_ALL",
};

export const STATUSES_ACTION_TYPES = {
    STATUSES_LOADING: 'STATUSES_LOADING',
    STATUSES_ERROR: "STATUSES_ERROR",
    STATUSES_CLEANUP: "STATUSES_CLEANUP",
    STATUSES_INSERT: "STATUSES_INSERT",
    STATUSES_UPDATE: "STATUSES_UPDATE",
    STATUSES_DELETE: "STATUSES_DELETE",
    STATUSES_GET_ALL: "STATUSES_GET_ALL",
};

export const COUNTRIES_ACTION_TYPES = {
    COUNTRIES_LOADING: 'COUNTRIES_LOADING',
    COUNTRIES_ERROR: "COUNTRIES_ERROR",
    COUNTRIES_CLEANUP: "COUNTRIES_CLEANUP",
    COUNTRIES_INSERT: "COUNTRIES_INSERT",
    COUNTRIES_UPDATE: "COUNTRIES_UPDATE",
    COUNTRIES_DELETE: "COUNTRIES_DELETE",
    COUNTRIES_GET_ALL: "COUNTRIES_GET_ALL",
};

export const COMPANIES_ACTION_TYPES = {
    COMPANIES_LOADING: 'COMPANIES_LOADING',
    COMPANIES_ERROR: "COMPANIES_ERROR",
    COMPANIES_CLEANUP: "COMPANIES_CLEANUP",
    COMPANIES_INSERT: "COMPANIES_INSERT",
    COMPANIES_UPDATE: "COMPANIES_UPDATE",
    COMPANIES_DELETE: "COMPANIES_DELETE",
    COMPANIES_GET_ALL: "COMPANIES_GET_ALL",
    ASSESSMENTS_LOADING: 'ASSESSMENTS_LOADING',
    ASSESSMENTS_ERROR: "ASSESSMENTS_ERROR",
    ASSESSMENTS_INSERT: "ASSESSMENTS_INSERT",
    ASSESSMENTS_UPDATE: "ASSESSMENTS_UPDATE",
    ASSESSMENTS_DELETE: "ASSESSMENTS_DELETE",
    ASSESSMENTS_GET_BY_COMPANY: "ASSESSMENTS_GET_BY_COMPANY",
};

export const ROLES_ACTION_TYPES = {
    ROLES_LOADING: 'ROLES_LOADING',
    ROLES_ERROR: "ROLES_ERROR",
    ROLES_CLEANUP: "ROLES_CLEANUP",
    ROLES_INSERT: "ROLES_INSERT",
    ROLES_UPDATE: "ROLES_UPDATE",
    ROLES_DELETE: "ROLES_DELETE",
    ROLES_GET_ALL: "ROLES_GET_ALL",
};

export const PERMISSIONS_ACTION_TYPES = {
    PERMISSIONS_LOADING: 'PERMISSIONS_LOADING',
    PERMISSIONS_ERROR: "PERMISSIONS_ERROR",
    PERMISSIONS_CLEANUP: "PERMISSIONS_CLEANUP",
    PERMISSIONS_SAVE: "PERMISSIONS_SAVE",
    PERMISSIONS_GET_ALL: "PERMISSIONS_GET_ALL",
};

export const VALUE_DRIVERS_ACTION_TYPES = {
    VALUE_DRIVERS_LOADING: "VALUE_DRIVERS_LOADING",
    VALUE_DRIVERS_ERROR: "VALUE_DRIVERS_ERROR",
    VALUE_DRIVERS_CLEANUP: "VALUE_DRIVERS_CLEANUP",
    VALUE_DRIVERS_INSERT: "VALUE_DRIVERS_INSERT",
    VALUE_DRIVERS_UPDATE: "VALUE_DRIVERS_UPDATE",
    VALUE_DRIVERS_DELETE: "VALUE_DRIVERS_DELETE",
    VALUE_DRIVERS_GET_ALL: "VALUE_DRIVERS_GET_ALL",
};

export const USERS_ACTION_TYPES = {
    USERS_LOADING: 'USERS_LOADING',
    USERS_ERROR: "USERS_ERROR",
    USERS_CLEANUP: "USERS_CLEANUP",
    USERS_INSERT: "USERS_INSERT",
    USERS_UPDATE: "USERS_UPDATE",
    USERS_DELETE: "USERS_DELETE",
    USERS_GET_ALL: "USERS_GET_ALL",
    USERS_SEND_INVITE_EMAIL: "USERS_SEND_INVITE_EMAIL",
};

export const USER_COMPANIES_ROLES_ACTION_TYPES = {
    USER_COMPANIES_ROLES_LOADING: 'USER_COMPANIES_ROLES_LOADING',
    USER_COMPANIES_ROLES_ERROR: "USER_COMPANIES_ROLES_ERROR",
    USER_COMPANIES_ROLES_CLEANUP: "USER_COMPANIES_ROLES_CLEANUP",
    USER_COMPANIES_ROLES_SAVE: "USER_COMPANIES_ROLES_SAVE",
    USER_COMPANIES_ROLES_GET_ALL: "USER_COMPANIES_ROLES_GET_ALL",
};

export const ASSESSMENT_USERS_ACTION_TYPES = {
    ASSESSMENT_USERS_LOADING: 'ASSESSMENT_USERS_LOADING',
    ASSESSMENT_USERS_ERROR: "ASSESSMENT_USERS_ERROR",
    ASSESSMENT_USERS_CLEANUP: "ASSESSMENT_USERS_CLEANUP",
    ASSESSMENT_USERS_GET_ALL: "ASSESSMENT_USERS_GET_ALL",
};

export const ASSESSMENTS_ACTION_TYPES = {
    ASSESSMENTS_LOADING: 'ASSESSMENTS_LOADING',
    ASSESSMENTS_ERROR: "ASSESSMENTS_ERROR",
    ASSESSMENTS_CLEANUP: "ASSESSMENTS_CLEANUP",
    ASSESSMENTS_INSERT: "ASSESSMENTS_INSERT",
    ASSESSMENTS_UPDATE: "ASSESSMENTS_UPDATE",
    ASSESSMENTS_DELETE: "ASSESSMENTS_DELETE",
    ASSESSMENTS_GET_ALL: "ASSESSMENTS_GET_ALL",
};

export const ANSWERS_ACTION_TYPES = {
    ANSWERS_LOADING: 'ANSWERS_LOADING',
    ANSWERS_ERROR: "ANSWERS_ERROR",
    ANSWERS_INSERT: "ANSWERS_INSERT",
    ANSWERS_UPDATE: "ANSWERS_UPDATE",
    ANSWERS_DELETE: "ANSWERS_DELETE",
    ANSWERS_GET_ALL: "ANSWERS_GET_ALL",
};

export const PRIO_ACTIONS_ACTION_TYPES = {
    PRIO_ACTIONS_LOADING: 'PRIO_ACTIONS_LOADING',
    PRIO_ACTIONS_ERROR: "PRIO_ACTIONS_ERROR",
    PRIO_ACTIONS_INSERT: "PRIO_ACTIONS_INSERT",
    PRIO_ACTIONS_UPDATE: "PRIO_ACTIONS_UPDATE",
    PRIO_ACTIONS_DELETE: "PRIO_ACTIONS_DELETE",
    PRIO_ACTIONS_GET_ALL: "PRIO_ACTIONS_GET_ALL",
};

export const PRIO_ITEMS_ACTION_TYPES = {
    PRIO_ITEMS_LOADING: 'PRIO_ITEMS_LOADING',
    PRIO_ITEMS_ERROR: "PRIO_ITEMS_ERROR",
    PRIO_ITEMS_INSERT: "PRIO_ITEMS_INSERT",
    PRIO_ITEMS_UPDATE: "PRIO_ITEMS_UPDATE",
    PRIO_ITEMS_DELETE: "PRIO_ITEMS_DELETE",
    PRIO_ITEMS_GET_ALL: "PRIO_ITEMS_GET_ALL",
};

export const STRATEGIC_INITIATIVES_ACTION_TYPES = {
    STRATEGIC_INITIATIVES_LOADING: 'STRATEGIC_INITIATIVES_LOADING',
    STRATEGIC_INITIATIVES_ERROR: "STRATEGIC_INITIATIVES_ERROR",
    STRATEGIC_INITIATIVES_INSERT: "STRATEGIC_INITIATIVES_INSERT",
    STRATEGIC_INITIATIVES_UPDATE: "STRATEGIC_INITIATIVES_UPDATE",
    STRATEGIC_INITIATIVES_DELETE: "STRATEGIC_INITIATIVES_DELETE",
    STRATEGIC_INITIATIVES_GET_ALL: "STRATEGIC_INITIATIVES_GET_ALL",
};

export const ACTION_ITEMS_ACTION_TYPES = {
    ACTION_ITEMS_LOADING: 'ACTION_ITEMS_LOADING',
    ACTION_ITEMS_ERROR: "ACTION_ITEMS_ERROR",
    ACTION_ITEMS_INSERT: "ACTION_ITEMS_INSERT",
    ACTION_ITEMS_UPDATE: "ACTION_ITEMS_UPDATE",
    ACTION_ITEMS_DELETE: "ACTION_ITEMS_DELETE",
    ACTION_ITEMS_GET_ALL: "ACTION_ITEMS_GET_ALL",
};

export const CYCLES_ACTION_TYPES = {
    CYCLES_LOADING: 'CYCLES_LOADING',
    CYCLES_ERROR: "CYCLES_ERROR",
    CYCLES_INSERT: "CYCLES_INSERT",
    CYCLES_UPDATE: "CYCLES_UPDATE",
    CYCLES_DELETE: "CYCLES_DELETE",
    CYCLES_GET_ALL: "CYCLES_GET_ALL",
};

export const MEMBER_ACTION_TYPES = {
    MEMBER_LOADING: 'MEMBER_LOADING',
    MEMBER_ERROR: "MEMBER_ERROR",
    MEMBER_CLEANUP: "MEMBER_CLEANUP",
    MEMBER_ERROR_CLEANUP: "MEMBER_ERROR_CLEANUP",
    MEMBER_SIGN_IN: "MEMBER_SIGN_IN",
    MEMBER_FORGOT_PASSWORD: "MEMBER_FORGOT_PASSWORD",
    MEMBER_PROFILE_UPDATE: "MEMBER_PROFILE_UPDATE",
    MEMBER_TOUR_UPDATE: "MEMBER_TOUR_UPDATE",
    MEMBER_PERMISSIONS: "MEMBER_PERMISSIONS",
}

export const SESSION_ACTION_TYPES = {
    SESSION_LOADING: 'SESSION_LOADING',
    SESSION_ERROR: "SESSION_ERROR",
    SESSION_ERROR_CLEANUP: "SESSION_ERROR_CLEANUP",
    SESSION_CLEANUP: "SESSION_CLEANUP",

    SESSION_SIDE_BAR_GET_MENUS: "SESSION_SIDE_BAR_GET_MENUS",
    SESSION_SIDE_BAR_SEARCH_MENUS_FILTER: "SESSION_SIDE_BAR_SEARCH_MENUS_FILTER",

    SESSION_GET_ASSOCIATED_ROLES: "SESSION_GET_ASSOCIATED_ROLES",

    SESSION_GET_ASSOCIATED_COMPANIES: "SESSION_GET_ASSOCIATED_COMPANIES",

    SESSION_GET_ASSOCIATED_USERS: "SESSION_GET_ASSOCIATED_USERS",
    SESSION_INSERT_ASSOCIATED_USER: "SESSION_INSERT_ASSOCIATED_USER",
    SESSION_UPDATE_ASSOCIATED_USER: "SESSION_UPDATE_ASSOCIATED_USER",
    SESSION_DELETE_ASSOCIATED_USER: "SESSION_DELETE_ASSOCIATED_USER",
    SESSION_INSERT_MULTIPLE_ASSOCIATED_USERS: "SESSION_INSERT_MULTIPLE_ASSOCIATED_USERS",
    SESSION_SEND_INVITE_EMAIL_ASSOCIATED_USER: "SESSION_SEND_INVITE_EMAIL_ASSOCIATED_USER",
    SESSION_SEND_LOCK_UNLOCK_ASSOCIATED_USER: "SESSION_SEND_LOCK_UNLOCK_ASSOCIATED_USER",

    SESSION_GET_ASSOCIATED_ASSESSMENTS: "SESSION_GET_ASSOCIATED_ASSESSMENTS",
    SESSION_INSERT_ASSOCIATED_ASSESSMENT: "SESSION_INSERT_ASSOCIATED_ASSESSMENT",
    SESSION_UPDATE_ASSOCIATED_ASSESSMENT: "SESSION_UPDATE_ASSOCIATED_ASSESSMENT",
    SESSION_DELETE_ASSOCIATED_ASSESSMENT: "SESSION_DELETE_ASSOCIATED_ASSESSMENT",

    SESSION_GET_ASSOCIATED_COMPANY_DRIVERS_SCORES: "SESSION_GET_ASSOCIATED_COMPANY_DRIVERS_SCORES",

    SESSION_GET_ASSOCIATED_USER_ASSESSMENT_SCORES: "SESSION_GET_ASSOCIATED_USER_ASSESSMENT_SCORES",
    SESSION_GET_ASSOCIATED_COMPANY_ASSESSMENT_SCORES: "SESSION_GET_ASSOCIATED_COMPANY_ASSESSMENT_SCORES",
    SESSION_GET_ASSOCIATED_USER_OVERALLS_SCORES: "SESSION_GET_ASSOCIATED_USER_OVERALLS_SCORES",

    SESSION_GET_ASSOCIATED_ASSESSMENT_USERS: "SESSION_GET_ASSOCIATED_ASSESSMENT_USERS",

    SESSION_GET_ASSOCIATED_ANSWERS: "SESSION_GET_ASSOCIATED_ANSWERS",
    SESSION_SAVE_ASSOCIATED_ANSWER: "SESSION_SAVE_ASSOCIATED_ANSWER",
    SESSION_SAVE_ASSOCIATED_ANSWER_DONE: "SESSION_SAVE_ASSOCIATED_ANSWER_DONE",

    SESSION_GET_ASSOCIATED_USER_ASSESSMENTS: "SESSION_GET_ASSOCIATED_USER_ASSESSMENTS",
    SESSION_SAVE_ASSOCIATED_USER_ASSESSMENT: "SESSION_SAVE_ASSOCIATED_USER_ASSESSMENT",

    SESSION_GET_ASSOCIATED_PRIO_ITEMS: "SESSION_GET_ASSOCIATED_PRIO_ITEMS",
    SESSION_SAVE_ASSOCIATED_PRIO_ITEM: "SESSION_SAVE_ASSOCIATED_PRIO_ITEM",
    SESSION_SAVE_ASSOCIATED_PRIO_ITEM_DONE: "SESSION_SAVE_ASSOCIATED_PRIO_ITEM_DONE",

    SESSION_GET_ASSOCIATED_PRIO_ACTIONS: "SESSION_ASSOCIATED_PRIO_ACTIONS",
    SESSION_INSERT_ASSOCIATED_PRIO_ACTION: "SESSION_INSERT_ASSOCIATED_PRIO_ACTION",
    SESSION_UPDATE_ASSOCIATED_PRIO_ACTION: "SESSION_UPDATE_ASSOCIATED_PRIO_ACTION",
    SESSION_DELETE_ASSOCIATED_PRIO_ACTION: "SESSION_DELETE_ASSOCIATED_PRIO_ACTION",
    SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER: "SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER",
    SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER_DONE: "SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER_DONE",

    SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES: "SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES",
    SESSION_INSERT_ASSOCIATED_STRATEGIC_INITIATIVE: "SESSION_INSERT_ASSOCIATED_STRATEGIC_INITIATIVE",
    SESSION_UPDATE_ASSOCIATED_STRATEGIC_INITIATIVE: "SESSION_UPDATE_ASSOCIATED_STRATEGIC_INITIATIVE",
    SESSION_DELETE_ASSOCIATED_STRATEGIC_INITIATIVE: "SESSION_DELETE_ASSOCIATED_STRATEGIC_INITIATIVE",

    SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER: "SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER",
    SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER_DONE: "SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER_DONE",

    SESSION_GET_ASSOCIATED_ACTION_ITEMS: "SESSION_GET_ASSOCIATED_ACTION_ITEMS",
    SESSION_INSERT_ASSOCIATED_ACTION_ITEM: "SESSION_INSERT_ASSOCIATED_ACTION_ITEM",
    SESSION_UPDATE_ASSOCIATED_ACTION_ITEM: "SESSION_UPDATE_ASSOCIATED_ACTION_ITEM",
    SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_DONE: "SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_DONE",
    SESSION_DELETE_ASSOCIATED_ACTION_ITEM: "SESSION_DELETE_ASSOCIATED_ACTION_ITEM",
    SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER: "SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER",
    SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER_DONE: "SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER_DONE",
    SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_STATE: "SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_STATE",

    SESSION_GET_ASSOCIATED_CYCLES: "SESSION_GET_ASSOCIATED_CYCLES",
    SESSION_INSERT_ASSOCIATED_CYCLE: "SESSION_INSERT_ASSOCIATED_CYCLE",
    SESSION_UPDATE_ASSOCIATED_CYCLE: "SESSION_UPDATE_ASSOCIATED_CYCLE",
    SESSION_DELETE_ASSOCIATED_CYCLE: "SESSION_DELETE_ASSOCIATED_CYCLE",
    SESSION_START_ASSOCIATED_CYCLE: "SESSION_START_ASSOCIATED_CYCLE",
    SESSION_STOP_ASSOCIATED_CYCLE: "SESSION_STOP_ASSOCIATED_CYCLE",

    SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER: "SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER",
    SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER_DONE: "SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER_DONE",

    SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER: "SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER",
    SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER_DONE: "SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER_DONE",

    SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_INSERT: "SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_INSERT",
    SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_UPDATE: "SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_UPDATE",
    SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_DELETE: "SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_DELETE",

    SESSION_ASSIGN_ASSOCIATED_EXTERNAL_USER: "SESSION_ASSIGN_ASSOCIATED_EXTERNAL_USER",
    SESSION_UNASSIGN_ASSOCIATED_EXTERNAL_USER: "SESSION_UNASSIGN_ASSOCIATED_EXTERNAL_USER",

    SESSION_GET_ASSOCIATED_ACTION_ITEMS_STATE_FILTER: "SESSION_GET_ASSOCIATED_ACTION_ITEMS_STATE_FILTER",
    SESSION_GET_ASSOCIATED_PRIO_ACTIONS_STATE_FILTER: "SESSION_GET_ASSOCIATED_PRIO_ACTIONS_STATE_FILTER",
    SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES_STATE_FILTER: "SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES_STATE_FILTER",

    SESSION_GET_ASSOCIATED_REPORTS_CYCLE_ACTIONITEMS_AUDITS: "SESSION_GET_ASSOCIATED_REPORTS_CYCLE_ACTIONITEMS_AUDITS",

    SESSION_GET_ASSOCIATED_OPEN_TASKS: "SESSION_GET_ASSOCIATED_OPEN_TASKS",

    SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS_WIDGETS: "SESSION_GET_ASSOCIATED_EXECHUB",

    SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS: "SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS",
    SESSION_INSERT_ASSOCIATED_EXECHUB_SECTION: "SESSION_INSERT_ASSOCIATED_EXECHUB_SECTION",
    SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION: "SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION",
    SESSION_DELETE_ASSOCIATED_EXECHUB_SECTION: "SESSION_DELETE_ASSOCIATED_EXECHUB_SECTION",
    SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER: "SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER",
    SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER_DONE: "SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER_DONE",

    SESSION_GET_ASSOCIATED_EXECHUB_WIDGETS: "SESSION_GET_ASSOCIATED_EXECHUB_WIDGETS",
    SESSION_INSERT_ASSOCIATED_EXECHUB_WIDGET: "SESSION_INSERT_ASSOCIATED_EXECHUB_WIDGET",
    SESSION_UPDATE_ASSOCIATED_EXECHUB_WIDGET: "SESSION_UPDATE_ASSOCIATED_EXECHUB_WIDGET",
    SESSION_DELETE_ASSOCIATED_EXECHUB_WIDGET: "SESSION_DELETE_ASSOCIATED_EXECHUB_WIDGET",

    SESSION_GET_ASSOCIATED_ASSESSMENTS_OVERALL_SCORES_COMPARISONS: "SESSION_GET_ASSOCIATED_ASSESSMENTS_OVERALL_SCORES_COMPARISONS",
    SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_SCORES_COMPARISONS: "SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_SCORES_COMPARISONS",

    SESSION_GET_ASSOCIATED_VALUE_DRIVERS_ACADEMIES: "SESSION_GET_ASSOCIATED_DRIVERS_ACADEMIES",

    SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_ANSWERS: "SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_ANSWERS",

    SESSION_GET_ASSOCIATED_COMPANY_VALUE_DRIVERS: "SESSION_GET_ASSOCIATED_COMPANY_VALUE_DRIVERS",
    SESSION_INSERT_ASSOCIATED_COMPANY_VALUE_DRIVER: "SESSION_INSERT_ASSOCIATED_COMPANY_VALUE_DRIVER",
    SESSION_UPDATE_ASSOCIATED_COMPANY_VALUE_DRIVER: "SESSION_UPDATE_ASSOCIATED_COMPANY_VALUE_DRIVER",
    SESSION_DELETE_ASSOCIATED_COMPANY_VALUE_DRIVER: "SESSION_DELETE_ASSOCIATED_COMPANY_VALUE_DRIVER",
    SESSION_DEACTIVATE_ACTIVATE_ASSOCIATED_COMPANY_VALUE_DRIVER: "SESSION_SEND_DEACTIVATE_DEACTIVATE_ASSOCIATED_COMPANY_VALUE_DRIVER",

    SESSION_GET_ASSOCIATED_SETTINGS: "SESSION_GET_ASSOCIATED_SETTINGS",
    SESSION_SAVE_ASSOCIATED_SETTINGS: "SESSION_SAVE_ASSOCIATED_SETTINGS",

    SESSION_GET_ASSOCIATED_APIKEY: "SESSION_GET_ASSOCIATED_APIKEY",
    SESSION_SAVE_ASSOCIATED_APIKEY: "SESSION_SAVE_ASSOCIATED_APIKEY",

    SESSION_REPORT_GET_COVQ_PLUS_VALUE_DRIVERS: "SESSION_REPORT_GET_COVQ_PLUS_VALUE_DRIVERS",
};

export const SIDE_MENUBAR_ACTION_TYPES = {
    SIDE_BAR_CLEANUP: "SIDE_BAR_CLEANUP",
    SIDE_BAR_SELECT_MENU: "SIDE_BAR_SELECT_MENU",
    SIDE_BAR_SELECT_SUBMENU: "SIDE_BAR_SELECT_SUBMENU",
}

export const EXTERNAL_USER_ACTION_TYPES = {
    EXTERNAL_USER_LOADING: 'EXTERNAL_USER_LOADING',
    EXTERNAL_USER_ERROR: "EXTERNAL_USER_ERROR",
    EXTERNAL_USER_ERROR_CLEANUP: "EXTERNAL_USER_ERROR_CLEANUP",
    EXTERNAL_USER_CLEANUP: "EXTERNAL_USER_CLEANUP",

    EXTERNAL_USER_GET_ASSOCIATED_ACTION_ITEM: "EXTERNAL_USER_GET_ASSOCIATED_ACTION_ITEM",
    EXTERNAL_USER_UPDATE_ASSOCIATED_ACTION_ITEM: "EXTERNAL_USER_UPDATE_ASSOCIATED_ACTION_ITEM",
}

export const ACADEMIES_ACTION_TYPES = {
    ACADEMIES_LOADING: 'ACADEMIES_LOADING',
    ACADEMIES_ERROR: "ACADEMIES_ERROR",
    ACADEMIES_CLEANUP: "ACADEMIES_CLEANUP",
    ACADEMIES_INSERT: "ACADEMIES_INSERT",
    ACADEMIES_UPDATE: "ACADEMIES_UPDATE",
    ACADEMIES_DELETE: "ACADEMIES_DELETE",
    ACADEMIES_GET_ALL: "ACADEMIES_GET_ALL",
};
