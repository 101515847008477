import {actionTypes} from "../constants";

const {VALUE_DRIVERS_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    valueDrivers: [],
};

const valueDriversReducer = (state = initialState, action) => {
    switch (action.type) {
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                valueDrivers: [...state.valueDrivers, action.payload],
            };
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                valueDrivers: state.valueDrivers.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                valueDrivers: state.valueDrivers.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                valueDrivers: action.payload,
            };
        }
        default:
            return state;
    }
}

export default valueDriversReducer;