import {actionTypes} from "../constants";

const {PERMISSIONS_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    permissions: [],
};

const permissonsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSIONS_ACTION_TYPES.PERMISSIONS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case PERMISSIONS_ACTION_TYPES.PERMISSIONS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case PERMISSIONS_ACTION_TYPES.PERMISSIONS_SAVE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                permissions: action.payload,
            };
        case PERMISSIONS_ACTION_TYPES.PERMISSIONS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                permissions: action.payload,
            };
        }
        default:
            return state;
    }
}

export default permissonsReducer;