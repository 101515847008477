import {actionTypes} from "../constants";

const {SESSION_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: false,
    error: false,
    reason: "",
    sidebarMenus: [],
    roles: [],
    companies: [],
    searchSidebarMenusFilter: "",
    users: [],
    companyValueDrivers: [],
    assessments: [],
    userAssessments: [],
    driversScores: [],
    answers: [],
    assessmentScore: [],
    userScores: [],
    companyScores: [],
    overallsScores: [],
    assessmentUsers: [],
    prioItems: [],
    prioActions: [],
    actionItems: [],
    strategicInitiatives: [],
    cycles: [],
    actionItemsStateFilter: [1, 2, 3],
    prioActionsStateFilter: [1, 2],
    strategicInitiativesFilter: [1, 2],
    reportsCycleActionItemsAudits: [],
    openTasks: {
        actionItems: [],
        prioActions: [],
        strategicInitiatives: [],
    },
    execHubSectionsWidgets: [],
    execHubSections: [],
    execHubWidgets: [],
    assessmentsCompaniesDriversAnswersAverages: [],
    assessmentsCompanyValueDriversTypesAnswersAverages: [],
    valueDriversAcademies: [],
    assessmentsCompaniesDriversAnswers: [],
    settings: {
        id: 0,
        emailNotificationsDisabled: false,
        emailNotificationsScheduled: false,
        emailNotificationsScheduledOnDate: null,
        emailNotificationsScheduledOnTime: null,
        companyId: 0,
        company: null,
    },
    apiKey: {
        id: 0,
        apiKey: "",
        companyId: 0,
        company: null,
    },
    reportCovQPlusCompanyValueDrivers: []
};

const sessionReducer = (state = initialState, action) => {

    switch (action.type) {
        case SESSION_ACTION_TYPES.SESSION_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_ERROR_CLEANUP: {
            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_CLEANUP: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                sidebarMenus: [],
                roles: [],
                companies: [],
                searchSidebarMenusFilter: "",
                users: [],
                companyValueDrivers: [],
                assessments: [],
                userAssessments: [],
                driversScores: [],
                answers: [],
                assessmentScore: [],
                userScores: [],
                companyScores: [],
                overallsScores: [],
                assessmentUsers: [],
                prioItems: [],
                prioActions: [],
                actionItems: [],
                strategicInitiatives: [],
                cycles: [],
                actionItemsStateFilter: [1, 2, 3],
                prioActionsStateFilter: [1, 2],
                strategicInitiativesFilter: [1, 2],
                reportsCycleActionItemsAudits: [],
                openTasks: {
                    actionItems: [],
                    prioActions: [],
                    strategicInitiatives: [],
                },
                execHubSectionsWidgets: [],
                execHubSections: [],
                execHubWidgets: [],
                assessmentsCompaniesDriversAnswersAverages: [],
                assessmentsCompanyValueDriversTypesAnswersAverages: [],
                valueDriversAcademies: [],
                assessmentsCompaniesDriversAnswers: [],
                settings: {
                    id: 0,
                    emailNotificationsDisabled: false,
                    emailNotificationsScheduled: false,
                    emailNotificationsScheduledOnDate: null,
                    emailNotificationsScheduledOnTime: null,
                    companyId: 0,
                    company: null,
                },
                apiKey: {
                    id: 0,
                    apiKey: "",
                    companyId: 0,
                    company: null,
                },
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SIDE_BAR_GET_MENUS: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                sidebarMenus: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ROLES: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                roles: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANIES:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_SIDE_BAR_SEARCH_MENUS_FILTER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                searchSidebarMenusFilter: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_DRIVERS_SCORES:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                driversScores: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USERS:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_USER: {

            const exists = state.users.some((user) => {
                return user.id === action.payload.id;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: exists ? state.users.map((user) => {
                    if (user.id === action.payload.id) {
                        return {
                            ...user,
                            ...action.payload
                        }
                    }

                    return user;
                }) : [...state.users, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_USER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: state.users.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_USER: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: state.users.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_MULTIPLE_ASSOCIATED_USERS:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_SEND_INVITE_EMAIL_ASSOCIATED_USER:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        case SESSION_ACTION_TYPES.SESSION_SEND_LOCK_UNLOCK_ASSOCIATED_USER: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: state.users.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_VALUE_DRIVERS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyValueDrivers: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_COMPANY_VALUE_DRIVER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyValueDrivers: [...state.companyValueDrivers, action.payload],
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_COMPANY_VALUE_DRIVER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyValueDrivers: state.companyValueDrivers.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_COMPANY_VALUE_DRIVER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyValueDrivers: state.companyValueDrivers.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DEACTIVATE_ACTIVATE_ASSOCIATED_COMPANY_VALUE_DRIVER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyValueDrivers: state.companyValueDrivers.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessments: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_ASSESSMENT: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessments: [...state.assessments, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ASSESSMENT: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessments: state.assessments.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_ASSESSMENT: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessments: state.assessments.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ANSWERS:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                answers: action.payload,
            };
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_ANSWER: {

            const exists = state.answers.some((answer) => {
                return answer.companyValueDriverId === action.payload.companyValueDriverId;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                answers: exists ? state.answers.map((answer) => {
                    if (answer.companyValueDriverId === action.payload.companyValueDriverId) {
                        return {
                            ...answer,
                            ...action.payload
                        }
                    }

                    return answer;
                }) : [...state.answers, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_ANSWER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_ASSESSMENTS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                userAssessments: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_USER_ASSESSMENT: {

            const exists = state.userAssessments.some((userAssessment) => {
                return userAssessment.assessmentId === action.payload.assessmentId;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                userAssessments: exists ? state.userAssessments.map((userAssessment) => {
                    if (userAssessment.id !== action.payload.assessmentId) {
                        return userAssessment;
                    }

                    return {
                        ...userAssessment,
                        ...action.payload
                    }
                }) : [...state.userAssessments, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_ASSESSMENT_SCORES: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                userScores: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_ASSESSMENT_SCORES: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companyScores: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_OVERALLS_SCORES: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                overallsScores: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENT_USERS: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentUsers: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_PRIO_ITEMS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioItems: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_PRIO_ITEM: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioItems: state.prioItems.map((item) => {
                    if (item.companyValueDriverId !== action.payload.companyValueDriverId) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_PRIO_ITEM_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioItems: state.prioItems.map((item) => {
                    if (item.companyValueDriverId !== action.payload.companyValueDriverId) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_PRIO_ACTIONS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_PRIO_ACTION: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: [...state.prioActions, action.payload],
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: state.prioActions.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_PRIO_ACTION: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: state.prioActions.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: state.prioActions.map((prioAction) => {

                    const orderedPRIOAction = action.payload.find((item) => (item.id === prioAction.id));

                    return orderedPRIOAction ? {...prioAction, ...orderedPRIOAction} : prioAction
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiatives: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_STRATEGIC_INITIATIVE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiatives: [...state.strategicInitiatives, action.payload],
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_STRATEGIC_INITIATIVE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiatives: state.strategicInitiatives.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_STRATEGIC_INITIATIVE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiatives: state.strategicInitiatives.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiatives: state.strategicInitiatives.map((strategicInitiative) => {
                    if (strategicInitiative.id !== action.payload.strategicInitiativeId) {
                        return strategicInitiative;
                    }

                    return {
                        ...strategicInitiative,
                        strategicInitiativesPRIOActions: action.payload.strategicInitiativesPRIOActions,
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ACTION_ITEMS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_ACTION_ITEM: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: [...state.actionItems, action.payload],
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_ACTION_ITEM: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.filter((item) => {
                    return item.id !== action.payload.id
                })
            }
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((actionItem) => {

                    const orderedActionItem = action.payload.find((item) => (item.id === actionItem.id));

                    return orderedActionItem ? {...actionItem, ...orderedActionItem} : actionItem
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_STATE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_CYCLES: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_CYCLE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: [...state.cycles, action.payload],
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_CYCLE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_CYCLE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.filter((item) => {
                    return item.id !== action.payload.id
                })
            }
        }
        case SESSION_ACTION_TYPES.SESSION_START_ASSOCIATED_CYCLE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_STOP_ASSOCIATED_CYCLE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: action.payload
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((cycle) => {
                    if (cycle.id !== action.payload.cycleId) {
                        return cycle;
                    }

                    return {
                        ...cycle,
                        cyclesActionItems: action.payload.cyclesActionItems
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER: {

            const cyclesActionItemsStates = [].concat(...state.cycles.map((cycle) => cycle.cyclesActionItemsStates))
                .filter((cycleActionItemState) =>
                    !(cycleActionItemState.cycleId === action.payload.cycleId && cycleActionItemState.state === action.payload.state))
                .concat(...action.payload.cyclesActionItemsStates)

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((cycle) => {
                    if (cycle.id !== action.payload.cycleId) {
                        return cycle;
                    }

                    return {
                        ...cycle,
                        cyclesActionItemsStates
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_INSERT: {

            const cyclesActionItemsStates = [].concat(...state.cycles.filter((cycle) => cycle.id === action.payload.cycleId))
                .map((cycle) => cycle.cyclesActionItemsStates)

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((cycle) => {
                    if (cycle.id !== action.payload.cycleId) {
                        return cycle;
                    }

                    return {
                        ...cycle,
                        cyclesActionItemsStates: [...cyclesActionItemsStates]
                            .filter((cycleActionItemState) => cycleActionItemState.actionItemId !== action.payload.actionItemId)
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_UPDATE: {

            const cyclesActionItemsStates = [].concat(...state.cycles.filter((cycle) => cycle.id === action.payload.cycleId))
                .map((cycle) => cycle.cyclesActionItemsStates)

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((cycle) => {
                    if (cycle.id !== action.payload.cycleId) {
                        return cycle;
                    }

                    return {
                        ...cycle,
                        cyclesActionItemsStates: [...cyclesActionItemsStates]
                            .filter((cycleActionItemState) => cycleActionItemState.actionItemId !== action.payload.actionItemId)
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_DELETE: {

            const cyclesActionItemsStates = [].concat(...state.cycles.filter((cycle) => cycle.id === action.payload.cycleId))
                .map((cycle) => cycle.cyclesActionItemsStates)

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                cycles: state.cycles.map((cycle) => {
                    if (cycle.id !== action.payload.cycleId) {
                        return cycle;
                    }

                    return {
                        ...cycle,
                        cyclesActionItemsStates: [...cyclesActionItemsStates, action.payload]
                    }
                }),
            };
        }
        case SESSION_ACTION_TYPES.SESSION_ASSIGN_ASSOCIATED_EXTERNAL_USER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.id !== action.payload.actionItemId) {
                        return item;
                    }

                    return {
                        ...item,
                        externalUser: action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UNASSIGN_ASSOCIATED_EXTERNAL_USER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.externalUser && item.externalUser.id === action.payload.id) {
                        return {
                            ...item,
                            externalUser: null
                        }
                    }

                    return item;
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ACTION_ITEMS_STATE_FILTER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItemsStateFilter: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_PRIO_ACTIONS_STATE_FILTER : {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActionsStateFilter: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES_STATE_FILTER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                strategicInitiativesFilter: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_REPORTS_CYCLE_ACTIONITEMS_AUDITS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                reportsCycleActionItemsAudits: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_OPEN_TASKS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                openTasks: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS_WIDGETS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSectionsWidgets: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSections: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_EXECHUB_SECTION: {

            const exists = state.execHubSections.some((execHubSection) => {
                return execHubSection.id === action.payload.id;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSections: exists ? state.execHubSections.map((execHubSection) => {
                    if (execHubSection.id === action.payload.id) {
                        return {
                            ...execHubSection,
                            ...action.payload
                        }
                    }

                    return execHubSection;
                }) : [...state.execHubSections, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSections: state.execHubSections.map((execHubSection) => {
                    if (execHubSection.id !== action.payload.id) {
                        return execHubSection;
                    }

                    return {
                        ...execHubSection,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_EXECHUB_SECTION: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSections: state.execHubSections.filter((execHubSection) => {
                    return execHubSection.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubSections: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER_DONE: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_WIDGETS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubWidgets: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_EXECHUB_WIDGET: {

            const exists = state.execHubWidgets.some((execHubWidget) => {
                return execHubWidget.id === action.payload.id;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubWidgets: exists ? state.execHubWidgets.map((execHubWidget) => {
                    if (execHubWidget.id === action.payload.id) {
                        return {
                            ...execHubWidget,
                            ...action.payload
                        }
                    }

                    return execHubWidget;
                }) : [...state.execHubWidgets, action.payload]
            };
        }
        case SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_WIDGET: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubWidgets: state.execHubWidgets.map((execHubWidget) => {
                    if (execHubWidget.id !== action.payload.id) {
                        return execHubWidget;
                    }

                    return {
                        ...execHubWidget,
                        ...action.payload
                    }
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_EXECHUB_WIDGET: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                execHubWidgets: state.execHubWidgets.filter((execHubWidget) => {
                    return execHubWidget.id !== action.payload.id
                })
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_OVERALL_SCORES_COMPARISONS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentsCompanyValueDriversTypesAnswersAverages: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_SCORES_COMPARISONS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentsCompaniesDriversAnswersAverages: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_VALUE_DRIVERS_ACADEMIES: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                valueDriversAcademies: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_ANSWERS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                assessmentsCompaniesDriversAnswers: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_SETTINGS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                settings: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_SETTINGS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_APIKEY: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                apiKey: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_APIKEY: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                apiKey: action.payload,
            };
        }
        case SESSION_ACTION_TYPES.SESSION_REPORT_GET_COVQ_PLUS_VALUE_DRIVERS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                reportCovQPlusCompanyValueDrivers: action.payload,
            };
        }
        default:
            return state;
    }
}

export default sessionReducer;