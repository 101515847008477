import {actionTypes} from "../constants";

const {ACTION_ITEMS_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    actionItems: [],
};

const actionItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: [...state.actionItems, action.payload],
            };
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: state.actionItems.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItems: action.payload,
            };
        }
        default:
            return state;
    }
}

export default actionItemsReducer;