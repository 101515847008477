import {actionTypes} from "../constants";

const {COUNTRIES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    countries: [],
};

const countriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case COUNTRIES_ACTION_TYPES.COUNTRIES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case COUNTRIES_ACTION_TYPES.COUNTRIES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case COUNTRIES_ACTION_TYPES.COUNTRIES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                countries: [...state.countries, action.payload],
            };
        case COUNTRIES_ACTION_TYPES.COUNTRIES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                countries: state.countries.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case COUNTRIES_ACTION_TYPES.COUNTRIES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                countries: state.countries.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case COUNTRIES_ACTION_TYPES.COUNTRIES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                countries: action.payload,
            };
        }
        default:
            return state;
    }
}

export default countriesReducer;