import {actionTypes} from "../constants";

const {EXTERNAL_USER_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    actionItem: null,
};

const externalUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_ERROR_CLEANUP: {
            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_GET_ASSOCIATED_ACTION_ITEM:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItem: action.payload,
            };
        case EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_UPDATE_ASSOCIATED_ACTION_ITEM:

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                actionItem: action.payload,
            };
        default:
            return state;
    }
}

export default externalUsersReducer;