import {actionTypes} from "../constants";

const {ACADEMIES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    academies: [],
};

const academiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACADEMIES_ACTION_TYPES.ACADEMIES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case ACADEMIES_ACTION_TYPES.ACADEMIES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case ACADEMIES_ACTION_TYPES.ACADEMIES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                academies: [...state.academies, action.payload],
            };
        case ACADEMIES_ACTION_TYPES.ACADEMIES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                academies: state.academies.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case ACADEMIES_ACTION_TYPES.ACADEMIES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                academies: state.academies.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case ACADEMIES_ACTION_TYPES.ACADEMIES_GET_ALL: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                academies: action.payload,
            };
        }
        default:
            return state;

    }
}

export default academiesReducer;