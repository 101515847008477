import {actionTypes} from "../constants";

const {SIDE_MENUBAR_ACTION_TYPES} = actionTypes;

const initialState = {
    selectedSideBarMenu: null,
    selectedSideBarSubMenu: null
};

const sideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_CLEANUP: {
            return {
                ...state,
                selectedSideBarMenu: null,
                selectedSideBarSubMenu: null
            };
        }
        case SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_MENU: {
            return {
                ...state,
                selectedSideBarMenu: action.payload,
            };
        }
        case SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_SUBMENU: {
            return {
                ...state,
                loading: false,
                error: true,
                selectedSideBarSubMenu: action.payload,
            };
        }
        default:
            return state;
    }
}

export default sideBarReducer;