const tourPages = {
    users: 1,
    companyValueDrivers: 2,
    assessments: 3,
    answers: 4,
    scores: 5,
    prioItems: 6,
    prioActions: 7,
    strategicInitiatives: 8,
    actionItems: 9,
    cycles: 10,
    reports: 11,
    execHub: 12,
    execHubSections: 13,
    execHubWidgets: 14,
    settings: 15,
}

export default tourPages