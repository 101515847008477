import {actionTypes} from "../constants";

const {PRIO_ACTIONS_ACTION_TYPES } = actionTypes;

const initialState = {
    loading: true,
    error: false,
    prioActions: [],
};

const prioActionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: [...state.prioActions, action.payload],
            };
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: state.prioActions.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: state.prioActions.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                prioActions: action.payload,
            };
        }
        default:
            return state;
    }
}

export default prioActionsReducer;