import React, {useEffect, useMemo} from "react";
import {AppProps} from "next/app";
import {useStore} from "react-redux";
import {useRouter} from "next/router";
import {PersistGate} from "redux-persist/integration/react";
import axios from "axios";
import NProgress from "nprogress";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {SnackbarProvider} from "notistack";
import LuxonUtils from "@date-io/luxon";

import {wrapper} from "../store";
import {axiosWithCredentials} from "../config";

import "../localization/i18n";

import "nprogress/nprogress.css";

import "../../styles/globals.css";
import "../../styles/fonts/Averta/averta.css";
import {colors} from "../constants";

import {defaults} from 'react-chartjs-2';

defaults.font.family = [
    "Averta",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
].join(",");

const {setupAxios, revokeAuthorization} = axiosWithCredentials;

setupAxios();

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, revokeAuthorization);

const useStyles = makeStyles(({palette}) => ({
    contentRoot: {
        color: palette.getContrastText(colors.black),
        backgroundColor: colors.black,
    },
    variantSuccess: {
        color: palette.getContrastText(colors.green),
        backgroundColor: colors.green,
    },
    variantError: {
        color: palette.getContrastText(colors.red),
        backgroundColor: colors.red,
    },
    variantInfo: {
        color: palette.getContrastText(colors.blue),
        backgroundColor: colors.blue,
    },
    variantWarning: {
        color: palette.getContrastText(colors.orange),
        backgroundColor: colors.orange,
    },
    secondaryColor: {
        color: colors.textColor,
        backgroundColor: colors.black,
    },
}));

const App = ({Component, pageProps}: AppProps) => {

    const router = useRouter();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const classes = useStyles();

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    type: prefersDarkMode ? "light" : "light", //"dark" : "light",
                    primary: {
                        main: colors.textColor,
                    },
                },
                typography: {
                    fontFamily: [
                        "Averta",
                        "-apple-system",
                        "BlinkMacSystemFont",
                        "Segoe UI",
                        "Roboto",
                        "Helvetica Neue",
                        "Arial",
                        "sans-serif",
                        "Apple Color Emoji",
                        "Segoe UI Emoji",
                        "Segoe UI Symbol",
                    ].join(","),
                },
                overrides: {
                    MuiTypography: {
                        colorPrimary: {
                            color: colors.textColor,
                        },
                        colorTextPrimary: {
                            color: colors.textColor
                        },
                        subtitle1: {
                            color: colors.textColor,
                        },
                        subtitle2: {
                            color: colors.textColor,
                        }
                    },
                    MuiTableRow: {
                        head: {
                            textTransform: "uppercase",
                        }
                    },
                    MuiTableCell: {
                        head: {
                            fontSize: "0.8rem"
                        },
                        body: {
                            color: colors.textColor,
                            fontSize: "0.8rem"
                        }
                    },
                    MuiListItemText: {
                        primary: {
                            // textTransform: "capitalize",
                            fontSize: "0.875rem",
                        }
                    },
                    MuiLink: {
                        root: {
                            textTransform: "capitalize",
                            alignSelf: "center",
                            color: colors.textColor,
                            textDecoration: "underline",
                        },
                        button: {

                            textDecoration: "underline",
                        }
                    },
                    MuiInputLabel: {
                        root: {
                            color: colors.textColor,
                            fontSize: 14,
                        },
                    },
                    MuiFormControlLabel: {
                        label: {
                            color: colors.textColor,
                            fontSize: 14,
                        },
                    },
                    MuiSelect: {
                        root: {
                            display: "flex"
                        }
                    },
                    MuiInputBase: {
                        root: {
                            backgroundColor: "inherit",
                        },
                        input: {
                            '&:-webkit-autofill': {
                                transitionDelay: '9999s',
                                transitionProperty: 'background-color, color',
                            },
                            '&:-webkit-autofill::first-line': {
                                fontFamily: [
                                    "Averta",
                                    "-apple-system",
                                    "BlinkMacSystemFont",
                                    "Segoe UI",
                                    "Roboto",
                                    "Helvetica Neue",
                                    "Arial",
                                    "sans-serif",
                                    "Apple Color Emoji",
                                    "Segoe UI Emoji",
                                    "Segoe UI Symbol",
                                ].join(","),
                                fontSize: "1rem"
                            }
                        },
                    },
                    MuiDialogTitle: {
                        root: {
                            color: colors.textColor,
                            fontWeight: "bold"
                        }
                    },
                    MuiDialogContent: {
                        root: {
                            color: colors.textColor,
                        }
                    },
                    MuiDialogContentText: {
                        root: {
                            color: colors.textColor,
                        }
                    },
                    MuiTab: {
                        root: {
                            borderTopColor: "transparent",
                            borderRightColor: "transparent",
                            borderBottomColor: "transparent",
                            borderLeftColor: "transparent",
                            borderWidth: 1,
                            borderStyle: "solid",
                        },
                        wrapper: {
                            alignItems: "self-start",
                            justifyContent: "flex-start",
                            textTransform: "capitalize",
                        },
                    }
                }
            }),
        [prefersDarkMode],
    );

    useEffect(() => {

        const routeChangeStart = () => NProgress.start();
        const routeChangeComplete = () => NProgress.done();

        router.events.on("routeChangeStart", routeChangeStart);
        router.events.on("routeChangeComplete", routeChangeComplete);
        router.events.on("routeChangeError", routeChangeComplete);

        return () => {
            router.events.off("routeChangeStart", routeChangeStart);
            router.events.off("routeChangeComplete", routeChangeComplete);
            router.events.off("routeChangeError", routeChangeComplete);
        };
    }, []);

    const store = useStore();
    // @ts-ignore
    const {__persistor} = store;
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <SnackbarProvider
                maxSnack={3}
                preventDuplicate={true}
                dense={false}
                hideIconVariant={false}
                classes={classes}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <PersistGate persistor={__persistor} loading={<div>Loading</div>}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Component {...pageProps} />
                    </MuiPickersUtilsProvider>
                </PersistGate>
            </SnackbarProvider>.
        </ThemeProvider>
    )
};

export default wrapper.withRedux(App);
