import {actionTypes} from "../constants";

const {MEMBER_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: false,
    error: false,
    member: null,
};

const memberReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEMBER_ACTION_TYPES.MEMBER_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_ERROR_CLEANUP: {
            return {
                ...state,
                loading: false,
                error: false,
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_CLEANUP: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_SIGN_IN:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                member: action.payload,
            };
        case MEMBER_ACTION_TYPES.MEMBER_FORGOT_PASSWORD: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_PROFILE_UPDATE: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                member: {...state.member, ...action.payload}
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_TOUR_UPDATE: {

            const exists = state.member.tours.some((tour) => {
                return tour.id === action.payload.id;
            });

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                member: exists ? {
                    ...state.member,
                    tours: state.member.tours.map((tour) => {
                        if (tour.id === action.payload.id) {
                            return {
                                ...tour,
                                ...action.payload
                            }
                        }

                        return tour;
                    })
                } : {
                    ...state.member,
                    tours: [...state.member.tours, action.payload]
                }
            };
        }
        case MEMBER_ACTION_TYPES.MEMBER_PERMISSIONS: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                member: {
                    ...state.member,
                    permissions: action.payload.permissions,
                    usersCompaniesRoles: action.payload.usersCompaniesRoles,
                }
            };
        }
        default:
            return state;
    }
}

export default memberReducer;