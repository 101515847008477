import {actionTypes} from "../constants";

const {ROLES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    roles: [],
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLES_ACTION_TYPES.ROLES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case ROLES_ACTION_TYPES.ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case ROLES_ACTION_TYPES.ROLES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                roles: [...state.roles, action.payload],
            };
        case ROLES_ACTION_TYPES.ROLES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                roles: state.roles.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case ROLES_ACTION_TYPES.ROLES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                roles: state.roles.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case ROLES_ACTION_TYPES.ROLES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                roles: action.payload,
            };
        }
        default:
            return state;
    }
}

export default rolesReducer;