import {actionTypes} from "../constants";

const {STATUSES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    statuses: [],
};

const statusesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATUSES_ACTION_TYPES.STATUSES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case STATUSES_ACTION_TYPES.STATUSES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case STATUSES_ACTION_TYPES.STATUSES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                statuses: [...state.statuses, action.payload],
            };
        case STATUSES_ACTION_TYPES.STATUSES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                statuses: state.statuses.map((lookupTable) => {
                    if (lookupTable.id === action.payload.id) {
                        return {
                            ...lookupTable,
                            ...action.payload
                        }
                    }

                    return lookupTable;
                })
            };
        case STATUSES_ACTION_TYPES.STATUSES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                statuses: state.statuses.filter((lookupTable) => {
                    return lookupTable.id !== action.payload.id
                })
            };
        case STATUSES_ACTION_TYPES.STATUSES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                statuses: action.payload,
            };
        }
        default:
            return state;
    }
}

export default statusesReducer;