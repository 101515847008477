import axios from 'axios';
import {apiUrls} from "../constants";

export const setAccessToken = (accessToken) => {

    localStorage.setItem("accessToken", accessToken);
};

export const getAccessToken = () => {

    return localStorage.getItem("accessToken");
};

export const setupAxios = () => {

    axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_URL;
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(request => {

        const accessToken = getAccessToken();

        if (accessToken) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return request;
    });
};

export const revokeAuthorization = failedRequest => {

    return axios.post(`${apiUrls.authenticate}/refresh_token`,
        {withCredentials: true})
        .then(({data}) => {

            setAccessToken(data.accessToken);

            failedRequest.response.config.headers.Authorization = `Bearer ${data.accessToken}`;

            return Promise.resolve();
        }).catch((reason => {

            return Promise.reject();
            console.log({reason});
        }));
};
