import {actionTypes} from "../constants";

const {USERS_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    users: [],
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS_ACTION_TYPES.USERS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case USERS_ACTION_TYPES.USERS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case USERS_ACTION_TYPES.USERS_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: [...state.users, action.payload],
            };
        case USERS_ACTION_TYPES.USERS_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: state.users.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case USERS_ACTION_TYPES.USERS_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: state.users.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case USERS_ACTION_TYPES.USERS_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                users: action.payload,
            };
        }
        case USERS_ACTION_TYPES.USERS_SEND_INVITE_EMAIL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
            };
        }
        default:
            return state;
    }
}

export default usersReducer;