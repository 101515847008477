import {actionTypes} from "../constants";

const {COMPANIES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    companies: [],
};

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANIES_ACTION_TYPES.COMPANIES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case COMPANIES_ACTION_TYPES.COMPANIES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case COMPANIES_ACTION_TYPES.COMPANIES_INSERT:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: [...state.companies, action.payload],
            };
        case COMPANIES_ACTION_TYPES.COMPANIES_UPDATE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: state.companies.map((item) => {
                    if (item.id !== action.payload.id) {
                        return item;
                    }

                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        case COMPANIES_ACTION_TYPES.COMPANIES_DELETE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: state.companies.filter((item) => {
                    return item.id !== action.payload.id
                })
            };
        case COMPANIES_ACTION_TYPES.COMPANIES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: action.payload,
            };
        }
        case COMPANIES_ACTION_TYPES.COMPANIES_CLEANUP: {

            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                companies: [],
            };
        }
        default:
            return state;
    }
}

export default companiesReducer;