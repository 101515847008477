import {actionTypes} from "../constants";

const {USER_COMPANIES_ROLES_ACTION_TYPES} = actionTypes;

const initialState = {
    loading: true,
    error: false,
    userCompaniesRoles: [],
};

const userCompaniesRolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
                reason: "",
            };
        }
        case USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
                reason: action.payload,
            };
        }
        case USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_SAVE:
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                userCompaniesRoles: action.payload,
            };
        case USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_GET_ALL: {
            return {
                ...state,
                loading: false,
                error: false,
                reason: "",
                userCompaniesRoles: action.payload,
            };
        }
        default:
            return state;
    }
}

export default userCompaniesRolesReducer;