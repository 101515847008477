
const pages = {
    read: "read",
    write: "write",
    delete: "delete",
    invite: "invite",
    readMine: "read-mine",
    readCompany: "read-company",
}

export default pages