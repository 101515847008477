import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import en from "./en/translation.json";
import de from "./de/translation.json";
import si from "./si/translation.json";

const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    },
    si: {
        translation: si
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ["navigator"],
        }
    }).catch((reason => {
    console.log({reason})
}));

export default i18n;