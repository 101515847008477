const permissionsPages = {
    users: "users",
    companyValueDrivers: "companyValueDrivers",
    assessments: "assessments",
    answers: "answers",
    scores: "scores",
    scoresDetails: "scoresDetails",
    label: "Scores",
    prioItems: "prioItems",
    prioActions: "prioActions",
    strategicInitiatives: "strategicInitiatives",
    actionItems: "actionItems",
    cycles: "cycles",
    reports: "reports",
    execHub: "execHub",
    execHubSections: "execHubSections",
    execHubWidgets: "execHubWidgets",
    academy: "academy",
    settings: "settings"
}

export default permissionsPages